import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../environments/environment'
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'ssb-personal-info-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ssb-personal-info';
  public personalInfoUrl;
  @ViewChild('iframe') iframe: ElementRef;
  constructor(private readonly domSanitizer: DomSanitizer) {
    
  }
  ngOnInit() {
    this.personalInfoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.personalInfoUrl);
  }
  public loadCSS(){
    const event = new CustomEvent('loadSSBPersonalInfo');
    document.dispatchEvent(event);
  }

}
